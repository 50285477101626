import React from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, image, keywords, canonical, twitterImage, facebookImage }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;

  console.log(
    '💻 Developed by James La Marre @ https://masthead.dev 💻'
  )

  return (
    <Helmet 
      title={metaTitle} 
      titleTemplate={title === 'Home' ? 'Temple Hill Entertainment' : `%s | Temple Hill`}
      htmlAttributes={{ lang, }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
            property: `og:locale`,
            content: `en_US`,
        },
        {
            property: `og:type`,
            content: title === 'Home' ? `website` : `article`,
        },
        {
            property: `article:publisher`,
            content: `Temple Hill Entertainment`,
        },
        {
            property: `og:url`,
            content: `https://www.templehillent.com/`,
        },
        {
            property: `og:site_name`,
            content: `Temple Hill`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: `image`,
          content: image ? image : null,
        },
        {
          name: `og:image`,
          content: facebookImage ? facebookImage : image ? image : null,
        },
        {
          name: `twitter:image`,
          content: twitterImage ? twitterImage : image ? image : null,
        },
        {
            rel: 'canonical',
            href: canonical,
        },
      ].concat(meta)}>
        <script type="application/ld+json">
          {`
              {
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  "url": "https://www.templehillent.com",
                  "name": "Temple Hill",
                  "description": "${metaDescription}",
                  "areaServed": "USA",
                  "founder": {
                      "@type": "Person",
                      "name": "Marty Bowen and Wyck Godfrey"
                  },
                  "foundingLocation": {
                      "@type": "Place",
                      "name": "USA"
                  }
              }
          `}
        </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO;